<template>
	<bs-popup id="master-vendor-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
		<template v-slot:title>
			Fee Profile
		</template>
		<template v-slot:content>
			<bs-error-message :errors="errorMessages" />
			<div class="mb-3">
				<label for="code" class="form-label">Name</label>
				<bs-textbox v-model="model.nama" />
				<bs-input-error :errors="errorMessages" keyError="name" />
			</div>

			<div class="mb-3">
				<label for="supplier" class="form-label">Supplier</label>
				<vendor-combo v-model="model.vendorId" :textDefault="model.vendorName"/>
				<bs-input-error :errors="errorMessages" keyError="Supplier"/>
			</div>

			<div class="mb-3">
				<label for="timeRange" class="form-label">Time Range</label>
				<time-range-combo v-model="model.timeRangeId" :textDefault="model.timeRangeNama"/>
				<bs-input-error :errors="errorMessages" keyError="Tipe Waktu"/>
			</div>

			<div class="mb-3">
				<label for="startDay" class="form-label">Start Day</label>
				<start-day-combo v-model="model.startDay" :textDefault="model.startDay"/>
				<bs-input-error :errors="errorMessages" keyError="Start Day"/>
			</div>

			<div class="mb-3">
				<label for="location" class="form-label">Location</label>
				<location-combo v-model="model.locationId" :textDefault="model.locationCode"/>
				<bs-input-error :errors="errorMessages" keyError="Lokasi"/>
			</div>

			<div class="mb-3">
                <label for="code" class="form-label">Airline</label>
                <airline-combo v-model="model.airlineId" />
                <bs-input-error :errors="errorMessages" keyError="AirlineCode" />
            </div>

			<div class="mb-3">
				<label for="currency" class="form-label">Currency</label>
				<currency-combo v-model="model.currencyId" :textDefault="model.currencyCode"/>
				<bs-input-error :errors="errorMessages" keyError="Currency"/>
			</div>

			<div class="mb-3">
				<label for="unit" class="form-label">Unit</label>
				<unit-combo v-model="model.unitId" :textDefault="model.unitCode"/>
				<bs-input-error :errors="errorMessages" keyError="Unit"/>
			</div>

			<div class="mb-3">
				<label for="feelist" class="form-label">Fee List</label>
				<div class="row mb-3" v-for="(item, index) in model.feeProfileList" :key="'feelist-' + index">
					<div class="col">
						<select v-model="model.feeProfileList[index].feeId" class="form-control" :disabled="id && item.id != 0">
							<option :value="list.id" v-for="list in feeList" :key="'feeoption-' + list.id">{{ list.name }}</option>
						</select>
					</div>
					<div class="col-auto" v-if="model.feeProfileList.length > 1">
						<button class="btn btn-sm btn-danger me-2" @click="() => deleteFee(index, item.id)" :disabled="item.id != 0 && model.feeProfileList.filter((el) => el.id != 0).length == 1">Delete Fee</button>
					</div>
				</div>
				<button class="btn btn-sm btn-primary me-2" @click="addFee" :disabled="!model.feeProfileList[model.feeProfileList.length-1].feeId">Add Fee</button>
			</div>

		</template>
		<template v-slot:footer>
			<button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
			<button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
		</template>
	</bs-popup>
</template>

<script>
import FeeProfileService from "@/services/fee-profile-service.js";

export default {
	components: {

	},
	data: function () {
		return {
			id: 0,
			model: FeeProfileService.default(),
			errorMessages: null,
			feeList: [
				{
					id: 1,
					name: "Government Fee",
				},
				{
					id: 2,
					name: "Throughput Fee",
				},
				{
					id: 3,
					name: "Flowage Fee",
				},
			],
		}
	},
	mounted() {
		let id = this.$route.params.id;
		if (id !== undefined && id !== null && id !== 0) {
			this.id = id;
			this.loadData();
		}
	},
	methods: {
		async loadData() {
			if (this.id === undefined || this.id === null || this.id === 0) {
				return;
			}

			const result = await FeeProfileService.getObject(this.id);
			if (result.status) {
				this.errorMessages = result.errorMessages;
			}
			else {
				this.errorMessages = null;
			}

			this.model = result.data;
		},
		async onPopupClosed() {
			this.$router.push('/fee-profile');
		},
		async close() {
			this.$refs.form.closePopup();
		},
		async saveData() {
			try {
				this.$store.commit('progressCounterQueueIncrement');

				let status = false;
				if (this.id !== undefined && this.id !== null && this.id !== 0) {
					const result = await FeeProfileService.update(this.model, this.id);
					status = result.status;
					if (status) {
						this.errorMessages = null;
					}
					else {
						this.errorMessages = result.errorMessages;
					}
				}
				else {
					const result = await FeeProfileService.create(this.model);
					status = result.status;
					console.log('save status', status);
					if (status) {
						this.errorMessages = null;
						this.id = result.data.id;
					}
					else {
						this.errorMessages = result.errorMessages;
					}
				}

				if (!status) return;

				this.$refs.form.closePopup();
				this.$router.push(`/fee-profile/detail/${this.id}`);
			}
			finally {
				this.$store.commit('progressCounterQueueDecrement');
			}
		},
		addFee(){
			this.model.feeProfileList.push({
				id: 0,
				feeProfileId: 0,
				feeId: 0,
				feeName: ""
			})
		},
		async deleteFee(index, id){
			if(id>0){
				try{
					this.$store.commit('progressCounterQueueIncrement');
					if (confirm(`Are you sure want to delete this "${this.model.feeProfileList[index].feeName}" from the fee profile?`)) {
						const result = await FeeProfileService.deleteListItem(id);
						if (result.status) {
							this.loadData();
						} else {
							alert(result.errorMessages['_ERRORS_'][0]);
						}
					}
				}
				finally {
					this.$store.commit('progressCounterQueueDecrement');
				}
			}else{
				this.model.feeProfileList.splice(index, 1);
			}
			
		}
	}
}
</script>